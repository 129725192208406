<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="form-group" :class="attrs.class">
    <div class="d-flex justify-between items-center">
      <label v-if="label" :for="id" class="form-label">{{ label }}</label>
      <slot />
    </div>
    <div class="input-wrapper">
      <component
        :is="isTextArea ? 'textarea' : 'input'"
        ref="input"
        class="form-control"
        :class="{
          'has-error': (errors && errors.length) || errorMessage,
          'show-validation': showValidation,
        }"
        :placeholder="placeholder"
        v-bind="inputAttrs"
        :value="modelValue"
        @input="emit('update:modelValue', (<HTMLInputElement>$event.target).value)" />
      <template v-if="showValidation">
        <ImagesSvg v-if="isValid" class="check" icon="check" width="24" height="24" />
        <ImagesSvg v-if="errorMessage" class="cross" icon="close" width="24" height="24" />
      </template>
    </div>
    <p v-if="errors && errors.length" class="form-error">
      <ImagesSvg icon="error" />
      <span>{{ cError }}</span>
    </p>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
const props = defineProps<{
  modelValue: string | number | null | undefined;
  label?: string;
  placeholder?: string;
  errors?: string[] | string;
  isTextArea?: boolean;
  showValidation?: boolean;
}>();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | null | undefined): void;
}>();

const attrs = useAttrs();
const id = String(attrs.id);

const inputAttrs = computed(() => {
  const attributes = { ...attrs };
  if (attributes && attributes.class) {
    delete attributes.class;
  }
  return attributes;
});

const input = ref<HTMLInputElement | null>(null);

const isValid = ref(false);
const errorMessage = ref('');

const cError = computed(() => {
  return Array.isArray(props.errors) ? props.errors[0] : props.errors;
});

onMounted(() => {
  isValid.value = input.value?.checkValidity() ?? false;
});

watch(
  () => props.modelValue,
  () => {
    isValid.value = input.value?.checkValidity() ?? false;
    input.value?.classList.toggle('has-error', !isValid.value);
    errorMessage.value = input.value?.validationMessage ?? '';
  }
);
</script>

<style lang="scss" scoped>
.form-group {
  .form-label {
    @include form-label;
  }

  .input-wrapper {
    position: relative;

    .form-control {
      @include form-control;

      &.show-validation {
        &:valid {
          border-color: $c-green-3;
        }

        &.has-error {
          border-color: $c-red-1;
        }
      }
    }

    .check,
    .cross {
      position: absolute;
      top: 50%;
      right: px(8);
      transform: translateY(-50%);
      flex-shrink: 0;
    }

    .check {
      color: $c-green-3;
    }

    .cross {
      color: $c-red-1;
    }
  }

  .form-error {
    color: $c-red-1;
    font-weight: 700;
    font-size: px(12);
    display: flex;
    align-items: center;
    gap: px(8);
    margin-top: px(12);
    margin-bottom: 0;

    &::v-deep(svg) {
      flex-shrink: 0;
    }
  }
}

textarea {
  height: px(115);
}
</style>
